import { Card, Grid, Icon, Pagination, Stack } from "@mui/material";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GetReq } from "lib/requests";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "examples/Tables/Table";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import { DeleteReq } from "lib/requests";
import { toast } from "react-hot-toast";
import { errorHandler } from "hooks/methods";
import CustomDialog from "components/Dialog";
import Loader from "components/Loader";
import SoftBadge from "components/SoftBadge";
import CustomCard from "components/CustomCard";
import SoftInput from "components/SoftInput";
import SearchIcon from "@mui/icons-material/Search";
import { IMAGE_BASE_URL } from "constants";

const ADS = () => {
  const { token } = useSelector((state) => state.user);

  const [ADSList, setADSList] = useState([]);
  const [ADSCount, setADSCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [currentAD, setCurrentAD] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortOn, setSortOn] = useState("createdAt");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await GetReq(
          `ad-list?page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
          true,
          token
        );
        console.log({ response });
        setADSList(response.data.ads);
        setADSCount(response.data.adCount);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token, page]);

  // useEffect(() => {
  //   (async () => {})();
  // }, [sortOn, search]);

  const handleChange = async (event, value) => {
    setPage(value);
    try {
      const response = await GetReq(
        `ad-list?page=${value}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setADSList(response.data.ads);
      setADSCount(response.data.adCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);

    try {
      const response = await GetReq(
        `ad-list?page=${page}&limit=${10}&sort=${sortOn}&search=${
          event.target.value
        }`,
        true,
        token
      );
      setADSList(response.data.ads);
      setADSCount(response.data.adCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteHandler = async (adId) => {
    setCurrentAD(adId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmationHandler = async () => {
    setOpen(false);
    setLoader(true);
    try {
      const response = await DeleteReq(`delete-ad/${currentAD}`, true, token);
      toast.remove();
      setADSList((prevState) =>
        prevState.filter((channel) => channel._id !== currentAD)
      );
      toast.success(response.data.message);
      let pageValue = page;
      if (ADSCount % 10 === 1 && ADSCount !== 1) {
        setPage(page - 1);
        pageValue -= 1;
      }
      const adResponse = await GetReq(
        `ad-list?page=${pageValue}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setADSList(adResponse.data.ads);
      setADSCount(adResponse.data.adCount);
      setLoader(false);
    } catch (error) {
      errorHandler(error);
      setLoader(false);
    }
  };

  // function Profile({ banner, name }) {
  //   return (
  //     <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
  //       <SoftBox mr={2}>
  //         <SoftAvatar
  //           src={`${IMAGE_BASE_URL}${banner}`}
  //           alt={name}
  //           size="sm"
  //           variant="rounded"
  //         />
  //       </SoftBox>
  //       <SoftBox display="flex" flexDirection="column">
  //         <SoftTypography variant="button" fontWeight="medium">
  //           {name}
  //         </SoftTypography>
  //       </SoftBox>
  //     </SoftBox>
  //   );
  // }

  const columns = [
    { name: "title", align: "left", sort: true },
    { name: "description", align: "center", sort: false },
    //   { name: "function", align: "left" },
    { name: "media type", align: "center", sort: false },
    //   { name: "status", align: "center" },
    { name: "created", align: "center", sort: true },
    { name: "status", align: "center", sort: true },
    { name: "action", align: "center", sort: false },
  ];

  const rows = [];

  const handleSort = async (name) => {
    let key = "cratedAt";
    if (name === "title") {
      if (sortOn === "title" && name[0] !== "-") {
        setSortOn(() => "-title");
        key = "-title";
      } else {
        setSortOn(() => "title");
        key = "title";
      }
    } else if (name === "created" && name[0] !== "-") {
      if (sortOn === "createdAt") {
        setSortOn(() => "-createdAt");
        key = "-createdAt";
      } else {
        setSortOn(() => "createdAt");
        key = "createdAt";
      }
    } else if (name === "status" && name[0] !== "-") {
      if (sortOn === "archived") {
        setSortOn(() => "-archived");
        key = "-archived";
      } else {
        setSortOn(() => "archived");
        key = "archived";
      }
    }

    try {
      const response = await GetReq(
        `ad-list?role=channel&page=${page}&limit=${10}&sort=${key}&search=${search}`,
        true,
        token
      );
      console.log(response);
      setADSList(response.data.ads);
      setADSCount(response.data.adCount);
    } catch (error) {
      errorHandler(error);
    }
    // await fetchData();
  };

  const pageCount = Math.ceil(ADSCount / 10);

  for (const row of ADSList) {
    rows.push({
      // channel: <Profile banner={row.banner} name={row.name} />,
      title: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.title.length > 20 ? row.title.slice(0, 21) + "..." : row.title}
        </SoftTypography>
      ),
      description: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.description.length > 20
            ? row.description.slice(0, 21) + "..."
            : row.description}
        </SoftTypography>
      ),
      created: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {new Date(row.createdAt).getDate()} /
          {new Date(row.createdAt).getMonth()} /
          {new Date(row.createdAt).getFullYear()}
        </SoftTypography>
      ),
      "media type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.type.length > 20 ? row.type.slice(0, 21) + "..." : row.type}
        </SoftTypography>
      ),
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent={row.archived === false ? "active" : "Inactive"}
          color={row.archived === false ? "success" : "error"}
          size="xs"
          container
        />
      ),
      action: (
        <>
          <Link to={`/edit-ad/${row._id}`}>
            <SoftButton
              color="info"
              variant="gradient"
              // onClick={handleWhiteSidenav}
              // disabled={disabled}
              // sx={sidenavTypeButtonsStyles}
              sx={{ marginRight: "10px" }}
            >
              Edit
            </SoftButton>
          </Link>
          <SoftButton
            color="error"
            variant="gradient"
            sx={{ marginLeft: "10px" }}
            onClick={() => deleteHandler(row._id)}
            // disabled={disabled}
            // sx={sidenavTypeButtonsStyles}
          >
            Delete
          </SoftButton>
        </>
      ),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loader && <Loader />}
      {!loader && (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                {/* <Stack direction="row" alignItems="center"> */}
                <SoftInput
                  name="search"
                  id="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search Channel"
                />
              </Grid>
              <SoftButton
                color="info"
                variant="gradient"
                onClick={() => navigate("/add-ads")}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
              >
                Add ADS
              </SoftButton>
            </Grid>
          </SoftBox>

          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">ADS table</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {!loader && (
                <Table
                  columns={columns}
                  rows={rows}
                  docCount={1}
                  handleSort={handleSort}
                  message="No channel exists."
                />
              )}
              <CustomDialog
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
                confirmationHandler={confirmationHandler}
                title="Are you sure?"
                content="You want to delete this channel?"
                loader={loader}
              />
            </SoftBox>
          </Card>

          {pageCount > 1 && (
            <Card
              sx={{
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
            </Card>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default ADS;
