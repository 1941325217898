import {
  FormControl,
  Grid,
  Icon,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import Multiselect from "multiselect-react-dropdown";
import React from "react";

// console.log()

const CastForm = ({
  handleSelect,
  handleRemove,
  values,
  multiSelectStyle,
  producers,
  directors,
  selectedArtists,
  handleArtistSelect,
  handleArtistRemove,
  artists,
  handleChangeArtistName,
  handleBlur,
  technicians,
  addArtistHandler,
  formCount,
  setFormCount,
  handleChange,
  channel,
}) => {
  console.log({ channel });
  return (
    <>
      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Producers
          </SoftTypography>
        </SoftBox>
        <FormControl sx={{ width: "100% !important" }}>
          <Multiselect
            style={multiSelectStyle}
            className="form-control"
            options={producers} // Options to display in the dropdown
            displayValue="name"
            onSelect={(event) => handleSelect(event, values, "producers")}
            onRemove={(event) => handleRemove(event, values, "producers")}
            // selectionLimit={1}
            // style={{}}
            closeOnSelect
            selectedValues={values.producers}
            // singleSelect={true}
          />
        </FormControl>
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          color="error"
        >
          {/* {errors.email && touched.email && errors.email} */}
        </SoftTypography>
      </SoftBox>
      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Directors
          </SoftTypography>
        </SoftBox>
        <FormControl sx={{ width: "100% !important" }}>
          <Multiselect
            style={multiSelectStyle}
            className="form-control"
            options={directors} // Options to display in the dropdown
            displayValue="name"
            onSelect={(event) => handleSelect(event, values, "directors")}
            onRemove={(event) => handleRemove(event, values, "directors")}
            // selectionLimit={1}
            // style={{}}
            closeOnSelect
            selectedValues={values.directors}
            // singleSelect={true}
          />
        </FormControl>
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          color="error"
        >
          {/* {errors.email && touched.email && errors.email} */}
        </SoftTypography>
      </SoftBox>
      {selectedArtists.map((_artist, index) => (
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={5}>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Artist
                  </SoftTypography>
                </SoftBox>
                <FormControl sx={{ width: "100% !important" }}>
                  <Multiselect
                    style={multiSelectStyle}
                    className="form-control"
                    options={artists} // Options to display in the dropdown
                    displayValue="name"
                    onSelect={(event) => handleArtistSelect(event, index)}
                    onRemove={(event) => handleArtistRemove(event, index)}
                    selectionLimit={1}
                    // style={{}}
                    closeOnSelect
                    selectedValues={_artist.id}
                    singleSelect={true}
                  />
                </FormControl>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {/* {errors.email && touched.email && errors.email} */}
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={5}>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Artist
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="artistName"
                  id="artistName"
                  value={_artist.name}
                  onBlur={handleBlur}
                  //   disabled={mode === "edit"}
                  onChange={(event) => handleChangeArtistName(index, event)}
                  type="text"
                  placeholder="Artist Name"
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {/* {errors.email && touched.email && errors.email} */}
                </SoftTypography>
              </SoftBox>
            </Grid>

            {index === selectedArtists.length - 1 && (
              <Icon
                style={{ cursor: "pointer" }}
                className="font-bold"
                fontSize="30px"
                onClick={addArtistHandler}
              >
                add
              </Icon>
            )}
          </Stack>
        </Grid>
      ))}
      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Technician
          </SoftTypography>
        </SoftBox>
        <FormControl sx={{ width: "100% !important" }}>
          <Multiselect
            style={multiSelectStyle}
            hidePlaceholder
            className="form-control"
            options={technicians} // Options to display in the dropdown
            displayValue="name"
            onSelect={(event) => handleSelect(event, values, "technicians")}
            onRemove={(event) => handleRemove(event, values, "technicians")}
            // selectionLimit={1}
            // style={{}}
            closeOnSelect
            selectedValues={values.technicians}
            // singleSelect={true}
          />
        </FormControl>
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          color="error"
        >
          {/* {errors.email && touched.email && errors.email} */}
        </SoftTypography>
      </SoftBox>

      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Channel
          </SoftTypography>
        </SoftBox>
        <FormControl sx={{ width: "100% !important" }}>
          <Select
            value={values.channel}
            name="channel"
            id="channel"
            onChange={handleChange}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
            }}
            sx={{
              paddingLeft: "0px !important",
            }}
          >
            <MenuItem id="channel" value="">
              <em>None</em>
            </MenuItem>
            {channel.length > 0 &&
              channel.map((_channel) => (
                <MenuItem id="channel" value={_channel._id} key={_channel._id}>
                  {_channel.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          color="error"
        >
          {/* {errors.email && touched.email && errors.email} */}
        </SoftTypography>
      </SoftBox>
    </>
  );
};

export default CastForm;
