import { Grid, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import React, { useState } from "react";

const FormButtons = ({
  formCount,
  setFormCount,
  values,
  genres,
  setErrorMessage,
  mode,
  setFieldValue
}) => {
  const fetchError = () => {
    if (formCount === 0) {
      let status = true;

      if (values.en.title === "" || values.sp.title === "") {
        setErrorMessage((prevState) => ({
          ...prevState,
          title: "Please fill title in both languages.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { title, ...rest } = prevState;
          return rest;
        });
      }
      if (values.en.plot === "" || values.sp.plot === "") {
        setErrorMessage((prevState) => ({
          ...prevState,
          plot: "Please fill plot in both languages.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { plot, ...rest } = prevState;
          return rest;
        });
      }
      if (values.genres.length === 0) {
        setErrorMessage((prevState) => ({
          ...prevState,
          genre: "Please select genres.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { genre, ...rest } = prevState;
          return rest;
        });
      }
      if (values.contentType === "") {
        setErrorMessage((prevState) => ({
          ...prevState,
          contentType: "Please select content type.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { contentType, ...rest } = prevState;
          return rest;
        });
      }
      if (values.rating === "") {
        setErrorMessage((prevState) => ({
          ...prevState,
          rating: "Please select rating.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { rating, ...rest } = prevState;
          return rest;
        });
      }
      return status;
    } else if (formCount === 1) {
      let status = true;

      if (
        (!values.poster || values.poster === "") &&
        (values.imageValue === "" || !values.imageValue)
      ) {
        setErrorMessage((prevState) => ({
          ...prevState,
          poster: "Please upload a poster.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { poster, ...rest } = prevState;
          return rest;
        });
      }
      if (!values.duration || values.duration === 0) {
        setErrorMessage((prevState) => ({
          ...prevState,
          duration: "Please fill duration.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { duration, ...rest } = prevState;
          return rest;
        });
      }
      if (!values.languages || values.languages.length === 0) {
        setErrorMessage((prevState) => ({
          ...prevState,
          languages: "Please select languages.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { languages, ...rest } = prevState;
          return rest;
        });
      }

      if (values.releaseDate === "") {
        setErrorMessage((prevState) => ({
          ...prevState,
          releaseDate: "Please select releaseDate.",
        }));
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { releaseDate, ...rest } = prevState;
          return rest;
        });
      }
      if (values.slider && (!values.trailer || values.trailer === null) && (!values.content || values.content === null)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          content: "Please select content.",
          trailer: "Please select trailer.",
        }));
        setFieldValue("trailerUrl", null)
        setFieldValue("contentUrl", null)
        status = false;
      } else {
        setErrorMessage((prevState) => {
          const { content, trailer, ...rest } = prevState;
          return rest;
        });

      }
      if (values.slider) {
        setFieldValue("trailerUrl", null)
        setFieldValue("contentUrl", null)
      }
      return status;
    }
  };

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={5}>
          <SoftBox mt={4} mb={1}>
            <SoftButton
              type="button"
              variant="gradient"
              color="info"
              fullWidth
              disabled={formCount <= 0}
              onClick={() => {
                setFormCount((prevState) => {
                  if (prevState > 0) {
                    return prevState - 1;
                  } else {
                    return prevState;
                  }
                });
              }}
            >
              Prev
            </SoftButton>
          </SoftBox>
        </Grid>
        <Grid item xs={5}>
          {formCount !== 2 && (
            <SoftBox mt={4} mb={1}>
              <SoftButton
                type="button"
                variant="gradient"
                color="info"
                fullWidth
                disabled={formCount >= 2}
                onClick={() => {
                  if (!fetchError()) {
                    return true;
                  } else {
                    setFormCount((prevState) => {
                      if (prevState < 2) {
                        return prevState + 1;
                      } else {
                        return prevState;
                      }
                    });
                  }
                }}
              >
                Next
              </SoftButton>
            </SoftBox>
          )}
          {formCount === 2 && (
            <SoftBox mt={4} mb={1}>
              <SoftButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
              >
                {mode === "add" ? "Add Content" : "Edit Content"}
              </SoftButton>
            </SoftBox>
          )}
        </Grid>
      </Stack>
    </Grid>
  );
};

export default FormButtons;
