import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

import { Formik } from "formik";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import VideoPreview from "components/VideoPreview";
import Multiselect from "multiselect-react-dropdown";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
// import signInSchema from "../schema/signInSchema";
import { PostReq } from "lib/requests";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { login } from "store/reducers/userSlice";
import {
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { errorHandler } from "hooks/methods";
import { GetReq } from "lib/requests";
import Loader from "components/Loader";
import { PatchReq } from "lib/requests";
import ImagePreview from "components/ImagePreview";
import * as Yup from "yup";

function ADSForm({ mode }) {
  const { token } = useSelector((state) => state.user);
  const [currentAD, setCurrentAD] = useState(null);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(0);
  const [interests, setInterests] = useState([]);
  const [errorState, setErrorState] = useState({
    skipTime: "",
    media: "",
    title: "",
    description: "",
    category: "",
  });


  const { adId } = useParams();

  const mediaInputRef = useRef();

  const initialState = {
    rewardPoints: 0,
    skipTime: 0,
    media: "",
    type: "",
    active: true,
    orientation: 'portrait',
    en: {
      title: "",
      description: "",
    },
    sp: {
      title: "",
      description: "",
    },
    video: "",
    category: "",
  };

  // useEffect(() => {
  //   if (mode === "edit") {
  //     (async () => {
  //       try {
  //         loader(true);
  //         const response = GetReq(`profile/${profileId}`, true, token);
  //         if (response) {
  //           initialState.name = response.data.profile.name;
  //           initialState.banner = response.data.profile.banner;
  //           initialState.active = !response.data.profile.archived;
  //           initialState.profile = response.data.profile.profile;
  //           initialState.role = response.data.profile.role;
  //           loader(false);
  //         } else {
  //           loader(false);
  //         }
  //       } catch (error) {
  //         loader(false);
  //         errorHandler(error);
  //       }
  //     })();
  //   }
  // }, [profileId, token, loader, mode]);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const interestResponse = await GetReq("interest", true, token);
      setInterests([...interestResponse.data.data]);
    })();
  }, [token]);

  useEffect(() => {
    if (mode.toLowerCase() === "edit") {
      (async () => {
        setLoader(true);
        try {
          const response = await GetReq(`ad/${adId}`, true, token);
          setCurrentAD(response.data.ad);
          setLoader(false);
        } catch (error) {
          errorHandler(error);
          setLoader(false);
        }
      })();
    }
  }, [token, mode, adId]);

  const submitHandler = async (values) => {

    let found = false
    if (!values.en.title || values.en.title.length === 0) {
      setErrorState(prevState => ({ ...prevState, title: "Please fill english title" }));
      found = true
    }
    if (!values.sp.title || values.sp.title.length === 0) {
      setErrorState(prevState => ({ ...prevState, title: "Please fill spainesh title" }))
      found = true
    }
    if ((!values.sp.title || values.sp.title.length === 0) && (!values.en.title || values.en.title.length === 0)) {
      setErrorState(prevState => ({ ...prevState, title: "Please fill spainesh title" }))
      found = true
    }

    if (!values.en.description || values.en.description.length === 0) {
      setErrorState(prevState => ({ ...prevState, description: "Please fill english description" }))
      found = true
    }
    if (!values.sp.description || values.sp.description.length === 0) {
      setErrorState(prevState => ({ ...prevState, "description": "Please fill spainesh description" }))
      found = true
    }
    if ((!values.sp.description || values.sp.description.length === 0) && (!values.en.description || values.en.description.length === 0)) {
      setErrorState(prevState => ({ ...prevState, "description": "Please fill spainesh description" }))
      found = true
    }
    if (!values.media && !values.video) {
      setErrorState(prevState => ({ ...prevState, "media": "Please fill add a media." }))
      found = true
    }
    if (values.skipTime === 0) {
      setErrorState(prevState => ({ ...prevState, "skipTime": "Please fill skip time." }))
      found = true
    }
    if (values.category.length === 0) {
      setErrorState(prevState => ({ ...prevState, "category": "Please fill interest." }))
      found = true
    }
    // console.log({ found })
    // console.log({ errorState })



    if (found) {
      return
    }

    if (mode.toLowerCase() === "add") {
      try {
        console.log({ values });
        setLoader(true);
        const { active, ...restValues } = values;

        // console.log(values);

        const formData = new FormData();
        formData.append("en", JSON.stringify(restValues.en));
        formData.append("sp", JSON.stringify(restValues.sp));
        formData.append("media", restValues.media);
        formData.append("skipTime", restValues.skipTime);
        formData.append("rewardPoints", restValues.rewardPoints);
        formData.append("category", restValues.category[0]._id);
        formData.append("type", "video");
        formData.append("orientation", restValues.orientation);
        formData.append("archived", !active);

        const response = await PostReq(
          "add-ad",
          formData,
          true,
          "multipart/form-data",
          token
        );
        if (response) {
          const successMessage = response.data.message;
          toast.remove();
          toast.success(successMessage);
          setLoader(false);
          navigate("/ads", { replace: true });
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    } else if (mode.toLowerCase() === "edit") {
      try {
        setLoader(true);
        const finalValues = { ...values, archived: !values.active };
        const formData = new FormData();
        formData.append("en", JSON.stringify(finalValues.en));
        formData.append("sp", JSON.stringify(finalValues.sp));
        formData.append("rewardPoints", finalValues.rewardPoints);
        formData.append("category", finalValues.category);
        formData.append("type", "video");
        formData.append("media", finalValues.media);

        formData.append("archived", finalValues.archived);

        const response = await PatchReq(
          `update-ad/${adId}`,
          formData,
          true,
          "multipart/form-data",
          token
        );
        if (response) {
          const successMessage = response.data.message;
          toast.remove();
          toast.success(successMessage);
          setLoader(false);
          navigate("/ads", { replace: true });
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    }
  };

  const multiSelectStyle = {
    inputField: {
      borderRadius: "0.75rem",
    },
    chips: {
      background: "#e9ecef",
      color: "black",
    },
    optionContainer: {
      borderRadius: "0.75rem",
      border: "none",
      fontSize: "0.875rem",
    },
    option: {
      borderRadius: "0.75rem",
      color: "#344767",
    },
  };

  const handleSelect = (event, values, field) => {
    values[field] = [...event];
  };

  const handleRemove = (event, values, field) => {
    values[field] = [...event];
  };

  if (currentAD) {
    initialState.type = currentAD.type;
    initialState.en = currentAD.translations.en;
    initialState.sp = currentAD.translations.sp;
    initialState.video = currentAD.video;
    initialState.rewardPoints = currentAD.rewardPoints;
    initialState.category = currentAD.category;
    initialState.skipTime = currentAD.skipTime;
    initialState.archived = !currentAD.archived;
  }

  console.log("err", errorState)


  useEffect(() => {
    console.log("useeffect", errorState)
  }, [errorState])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <Grid
          container
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Grid item xs={6} md={6} lg={6}>
            <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  {mode === "add" ? "Add AD" : "Edit AD"}
                </SoftTypography>
              </SoftBox>
              <SoftBox p={3} mb={1}>
                <Formik
                  initialValues={initialState}
                  // validationSchema={Yup.object().shape({
                  //   name: Yup.string()
                  //     .required("Name is required.")
                  //     .matches(nameRegExp, "Name must be valid"),
                  // })}
                  onSubmit={(values) => submitHandler(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <SoftBox>
                        <Grid item xs={6}>
                          <Card
                            sx={{ padding: "5px" }}
                            style={{ backgroundColor: "#f0f1f2" }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={6}>
                                <SoftButton
                                  type="button"
                                  onClick={() => setValue(0)}
                                  disabled={value === 0}
                                  fullWidth
                                  sx={{ padding: "1px !important" }}
                                >
                                  English
                                </SoftButton>
                              </Grid>
                              <Grid item xs={6}>
                                <SoftButton
                                  onClick={() => setValue(1)}
                                  type="button"
                                  disabled={value === 1}
                                  fullWidth
                                >
                                  Spainesh
                                </SoftButton>
                              </Grid>
                            </Stack>
                          </Card>
                        </Grid>
                        <SoftBox mb={2}>
                          <Grid item xs={12} mb={2}>
                            <Stack
                              // justifyContent="center"
                              // alignItems="center"
                              direction="row"
                            >
                              <Grid item xs={6}>
                                <SoftBox ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    title
                                  </SoftTypography>
                                </SoftBox>
                              </Grid>
                            </Stack>
                          </Grid>
                          {value === 0 && (
                            <SoftInput
                              name="en.title"
                              id="en.title"
                              value={values.en.title}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              placeholder="Title"
                            />
                          )}
                          {value === 1 && (
                            <SoftInput
                              name="sp.title"
                              id="sp.title"
                              value={values.sp.title}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              placeholder="Title"
                            />
                          )}

                        </SoftBox>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          color="error"
                        >
                          {errorState.title && errorState.title}

                        </SoftTypography>

                        <SoftBox mb={2}>
                          <Grid item xs={12} mb={2}>
                            <Stack
                              // justifyContent="center"
                              // alignItems="center"
                              direction="row"
                            >
                              <Grid item xs={6}>
                                <SoftBox ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Description
                                  </SoftTypography>
                                </SoftBox>
                              </Grid>
                            </Stack>
                          </Grid>
                          {value === 0 && (
                            <div
                              style={{
                                border: "0.0625rem solid #d2d6da",
                                padding: "8px 12px",
                                minWidth: "90%",
                                borderRadius: "0.5rem",
                                cursor: "text",
                              }}
                            >
                              <textarea
                                value={values.en.description}
                                placeholder="Description"
                                onChange={handleChange}
                                id="en.description"
                                name="en.description"
                                style={{
                                  maxWidth: "100%",
                                  minWidth: "100%",
                                  border: "none",
                                  outline: "none",
                                }}
                              ></textarea>
                            </div>
                          )}
                          {value === 1 && (
                            <div
                              style={{
                                border: "0.0625rem solid #d2d6da",
                                padding: "8px 12px",
                                minWidth: "90%",
                                borderRadius: "0.5rem",
                                cursor: "text",
                              }}
                            >
                              <textarea
                                value={values.sp.description}
                                placeholder="Description"
                                onChange={handleChange}
                                id="sp.description"
                                name="sp.description"
                                style={{
                                  maxWidth: "100%",
                                  minWidth: "100%",
                                  border: "none",
                                  outline: "none",
                                }}
                              ></textarea>
                            </div>
                          )}
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errorState.description && errorState.description}

                          </SoftTypography>
                        </SoftBox>

                        {/* <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Type
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="type"
                            id="type"
                            value={values.type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            placeholder="Type"
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.type && touched.type && errors.type}
                          </SoftTypography>
                        </SoftBox> */}
                        <Stack direction="row">
                          {/* <SoftBox mb={2}> */}
                          <Grid item xs={12} mb={2}>
                            {/* <Stack
                                // justifyContent="center"
                                // alignItems="center"
                                direction="row"
                              > */}
                            <Grid item xs={6}>
                              <SoftBox ml={0.5}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Reward Points
                                </SoftTypography>
                              </SoftBox>
                              <SoftInput
                                name="rewardPoints"
                                id="rewardPoints"
                                value={values.rewardPoints}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="number"
                                placeholder="Reward Points"
                              />
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                color="error"
                              >
                                {/* {errors.email && touched.email && errors.email} */}
                              </SoftTypography>
                            </Grid>
                            {/* </Stack> */}
                          </Grid>
                          {/* </SoftBox> */}
                          {/* <SoftBox mb={2}> */}
                          <Grid item xs={12} mb={2}>
                            <Stack
                              // justifyContent="center"
                              // alignItems="center"
                              direction="row"
                            >
                              <Grid item xs={6}>
                                <SoftBox ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Skip time
                                  </SoftTypography>
                                </SoftBox>
                                <SoftInput
                                  name="skipTime"
                                  id="skipTime"
                                  value={values.skipTime}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="number"
                                  placeholder="Skip time in sec"
                                />
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {errorState.skipTime && errorState.skipTime}

                                </SoftTypography>
                              </Grid>
                            </Stack>
                          </Grid>
                          {/* </SoftBox> */}
                        </Stack>
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Interest
                            </SoftTypography>
                          </SoftBox>
                          <Multiselect
                            style={multiSelectStyle}
                            selectionLimit={1}
                            className="form-control"
                            options={interests} // Options to display in the dropdown
                            displayValue="title"
                            onSelect={(event) =>
                              handleSelect(event, values, "category")
                            }
                            onRemove={(event) =>
                              handleRemove(event, values, "category")
                            }
                            // selectionLimit={1}
                            // style={{}}
                            onBlur={handleBlur}
                            id="category"
                            name="category"
                            closeOnSelect
                            selectedValues={values.category}
                          // singleSelect={true}
                          />
                          {errorState.category && (
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="error"
                            >
                              {errorState.category}
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <Grid item xs={5}>
                          <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Orientation
                              </SoftTypography>
                            </SoftBox>
                            <FormControl sx={{ width: "100% !important" }}>
                              <Select
                                value={values.orientation}
                                name="orientation"
                                id="orientation"
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                sx={{
                                  paddingLeft: "0px !important",
                                }}
                              >
                                {/* <MenuItem id="orientation" value="">
                                  <em>None</em>
                                </MenuItem> */}
                                <MenuItem id="orientation" value="portrait">
                                  Portrait
                                </MenuItem>
                                <MenuItem id="orientation" value="landscape">
                                  Landscape
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </SoftBox>
                        </Grid>
                        <Grid item xs={10}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Media
                                  </SoftTypography>
                                </SoftBox>
                                <input
                                  name="media"
                                  id="media"
                                  hidden
                                  ref={mediaInputRef}
                                  //   value={values.type}
                                  onBlur={handleBlur}
                                  //   disabled={mode === "edit"}
                                  accept="video/*"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "media",
                                      event.target.files[0]
                                    );
                                  }}
                                  type="file"
                                  placeholder="Media"
                                />
                                <SoftButton
                                  type="button"
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  onClick={() => mediaInputRef.current.click()}
                                >
                                  Add Media
                                </SoftButton>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {errorState.media && errorState.media}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                            {(values.media || values.video) && (
                              <VideoPreview
                                // image={values.video}
                                file={values.media}
                              />
                            )}
                          </Stack>
                        </Grid>
                        <SoftBox mt={3} mb={2} lineHeight={1}>
                          <SoftTypography variant="h6">Status</SoftTypography>

                          <Switch
                            id="active"
                            name="active"
                            checked={values.active}
                            onChange={handleChange}
                            value={values.active}
                          />
                        </SoftBox>
                        <SoftBox mt={4} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {mode === "add" ? "Add Ad" : "Edit AD"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </form>
                  )}
                </Formik>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default ADSForm;
