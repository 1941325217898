import { Card, Grid, Icon, Pagination, Stack } from "@mui/material";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GetReq } from "lib/requests";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "examples/Tables/Table";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import { DeleteReq } from "lib/requests";
import { toast } from "react-hot-toast";
import { errorHandler } from "hooks/methods";
import CustomDialog from "components/Dialog";
import Loader from "components/Loader";
import SoftBadge from "components/SoftBadge";
import CustomCard from "components/CustomCard";
import SoftInput from "components/SoftInput";
import SearchIcon from "@mui/icons-material/Search";
import { IMAGE_BASE_URL } from "constants";

const Subscriptions = () => {
  const { token } = useSelector((state) => state.user);

  const [planList, setPlanList] = useState([]);
  const [planCount, setPlanCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortOn, setSortOn] = useState("createdAt");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await GetReq(
          `subscription-plan?page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
          true,
          token
        );
        const planList = response.data?.data || []
        setPlanList([...planList]);
        setPlanCount(response.data.subscriptionPlanCount);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token, page]);

  const handleChange = async (event, value) => {
    setPage(value);
    try {
      const response = await GetReq(
        `subscription-plan?page=${value}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setPlanList([...response.data?.data]);
      setPlanCount(response.data.subscriptionPlanCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);

    try {
      const response = await GetReq(
        `subscription-plan?page=${page}&limit=${10}&sort=${sortOn}&search=${event.target.value
        }`,
        true,
        token
      );
      setPlanList([...response.data?.data]);
      setPlanCount(response.data.subscriptionPlanCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteHandler = async (subscriptionId) => {
    setCurrentSubscription(subscriptionId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmationHandler = async () => {
    setOpen(false);
    setLoader(true);
    try {
      const response = await DeleteReq(
        `subscription-plan/${currentSubscription}`,
        true,
        token
      );
      toast.remove();
      setPlanList((prevState) =>
        prevState.filter((talent) => talent._id !== currentSubscription)
      );
      toast.success(response.data.message);
      let pageValue = page;
      if (planCount % 10 === 1 && planCount !== 1) {
        setPage(page - 1);
        pageValue -= 1;
      }
      const res = await GetReq(
        `subscription-plan?page=${pageValue}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setPlanList([...res.data?.data]);
      setPlanCount(res.data.subscriptionPlanCount);
      setLoader(false);
      setLoader(false);
    } catch (error) {
      errorHandler(error);
      setLoader(false);
    }
  };

  function Profile({ name }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const columns = [
    { name: "title", align: "left", sort: false },
    { name: "type", align: "center", sort: false },
    //   { name: "function", align: "left" },
    //   { name: "status", align: "center" },
    { name: "applied coupon", align: "center", sort: false },
    { name: "price", align: "center", sort: false },
    { name: "plan created", align: "center", sort: false },
    { name: "status", align: "center", sort: false },
    { name: "action", align: "center", sort: false },
  ];

  const rows = [];

  //   const handleSort = async (name) => {
  //     let key = "cratedAt";
  //     if (name === "talent") {
  //       if (sortOn === "name" && name[0] !== "-") {
  //         setSortOn(() => "-name");
  //         key = "-name";
  //       } else {
  //         setSortOn(() => "name");
  //         key = "name";
  //       }
  //     } else if (name === "account created" && name[0] !== "-") {
  //       if (sortOn === "createdAt") {
  //         setSortOn(() => "-createdAt");
  //         key = "-createdAt";
  //       } else {
  //         setSortOn(() => "createdAt");
  //         key = "createdAt";
  //       }
  //     } else if (name === "status" && name[0] !== "-") {
  //       if (sortOn === "archived") {
  //         setSortOn(() => "-archived");
  //         key = "-archived";
  //       } else {
  //         setSortOn(() => "archived");
  //         key = "archived";
  //       }
  //     }

  //     try {
  //       const response = await GetReq(
  //         `get-profiles?role=talent&page=${page}&limit=${10}&sort=${key}&search=${search}`,
  //         true,
  //         token
  //       );
  //       console.log(response);
  //       setTalentList(response.data.profiles);
  //       setTalentCount(response.data.profileCount);
  //     } catch (error) {
  //       errorHandler(error);
  //     }
  //     // await fetchData();
  //   };

  const pageCount = Math.ceil(planCount / 10);

  for (const row of planList) {
    rows.push({
      title: <Profile name={row.planTitle} />,
      type: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.subscriptionType}
        </SoftTypography>
      ),
      "applied coupon": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.offer || "-"}
        </SoftTypography>
      ),
      price: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.planPrice || 0}
        </SoftTypography>
      ),
      "plan created": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {new Date(row.createdAt).getDate()} /
          {new Date(row.createdAt).getMonth()} /
          {new Date(row.createdAt).getFullYear()}
        </SoftTypography>
      ),
      "profile description": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.translations?.en?.profile}
        </SoftTypography>
      ),
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent={row.archived === false ? "active" : "Inactive"}
          color={row.archived === false ? "success" : "error"}
          size="xs"
          container
        />
      ),
      action: (
        <>
          <SoftButton
            color="error"
            variant="gradient"
            sx={{ marginLeft: "10px" }}
            onClick={() => deleteHandler(row._id)}
          // disabled={disabled}
          // sx={sidenavTypeButtonsStyles}
          >
            Delete
          </SoftButton>
        </>
      ),
    });
  }
  {
    /* <Link to={`/edit-talent/${row._id}`}>
              <SoftButton
                color="info"
                variant="gradient"
                // onClick={handleWhiteSidenav}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
                sx={{ marginRight: "10px" }}
              >
                Edit
              </SoftButton>
            </Link> */
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loader && <Loader />}
      {!loader && (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                {/* <Stack direction="row" alignItems="center"> */}
                <SoftInput
                  name="search"
                  id="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search Subscription"
                />
                {/* <button
                    style={{
                      padding: "5px 30px",
                      cursor: "pointer",
                      background: "white",
                      border: "0.0625rem solid #d2d6da",
                      borderRadius: "5px",
                    }}
                    type="button"
                    onClick={handleSearch}
                  >
                    <Icon
                      fontSize="30px"
                      sx={{ color: "#d2d6da !important" }}
                      color="#d2d6da !important"
                    >
                      search
                    </Icon>
                  </button> */}
                {/* </Stack> */}
              </Grid>
              <SoftButton
                color="info"
                variant="gradient"
                onClick={() => navigate("/add-subscription")}
              // disabled={disabled}
              // sx={sidenavTypeButtonsStyles}
              >
                Add Subscription
              </SoftButton>
            </Grid>
          </SoftBox>

          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Subscription Table</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {!loader && (
                <Table
                  columns={columns}
                  rows={rows}
                  docCount={planCount}
                  // handleSort={handleSort}
                  message="No subscription exists."
                />
              )}
              <CustomDialog
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
                confirmationHandler={confirmationHandler}
                title="Are you sure?"
                content="You want to delete this subscription?"
                loader={loader}
              />
            </SoftBox>
          </Card>

          {pageCount > 1 && (
            <Card
              sx={{
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
            </Card>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Subscriptions;
