/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import { useEffect, useState } from "react";
import { GetReq } from "lib/requests";
import { useSelector } from "react-redux";
import { errorHandler } from "hooks/methods";
import Loader from "components/Loader";
import UserChart from "./components/UserChart";
import Table from "examples/Tables/Table";
import { Typography } from "@mui/material";

function Dashboard() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const [loader, setLoader] = useState(false);
  const [dataCount, setDataCount] = useState({
    userCount: 0,
    talentCount: 0,
    channelCount: 0,
    movieCount: 0,
    seriesCount: 0,
  });
  const [chartData, setChartData] = useState({
    userData: null,
    talentData: null,
    channelData: null,
    moviesData: null,
    seriesData: null,
  });
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      try {
        setLoader(true);
        const response = await GetReq("dashboard/user-count", true, token);
        if (response) {
          setDataCount((prevState) => ({
            ...prevState,
            userCount: response.data.data.userCount,
            talentCount: response.data.data.talentCount,
            channelCount: response.data.data.channelCount,
            movieCount: response.data.data.movieCount,
            seriesCount: response.data.data.seriesCount,
          }));
          setLoader(false);
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    })();
  }, [token]);

  useEffect(() => {
    (async () => {
      try {
        setLoader(true);
        const response = await GetReq("dashboard/user-analytics", true, token);
        if (response) {
          setChartData((prevState) => ({
            ...prevState,
            userData: response.data.data.usersData,
            talentData: response.data.data.talentsData,
            channelData: response.data.data.channelsData,
            moviesData: response.data.data.moviesData,
            seriesData: response.data.data.seriesData,
          }));
          setLoader(false);
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    })();
  }, [token]);

  const contentColumns = [
    { name: "title", align: "left", sort: false },
    { name: "view time", align: "left", sort: false },
    { name: "Abandon rate", align: "center", sort: false },
    { name: "likes", align: "center", sort: false },
  ];

  const usersColumns = [
    { name: "user", align: "left", sort: false },
    { name: "view time", align: "left", sort: false },
    { name: "Abandon rate", align: "center", sort: false },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                  title={{ text: "Total active users" }}
                  count={dataCount.userCount}
                  icon={{ color: "info", component: "persons" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                  title={{ text: "Total talent" }}
                  count={dataCount.talentCount}
                  icon={{ color: "info", component: "public" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                  title={{ text: "Total channels" }}
                  count={dataCount.channelCount}
                  icon={{ color: "info", component: "emoji_events" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                  title={{ text: "Total Movie" }}
                  count={dataCount.movieCount}
                  icon={{
                    color: "info",
                    component: "shopping_cart",
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>

          <SoftBox mb={3}>
            <Grid container spacing={3} justifyContent="space-between">
              {chartData && chartData.userData && (
                <Grid item xs={12} lg={6}>
                  <UserChart userChartData={chartData.userData} title="user" />
                </Grid>
              )}
              {chartData && chartData.talentData && (
                <Grid item xs={12} lg={6}>
                  <UserChart
                    userChartData={chartData.talentData}
                    title="talent"
                  />
                </Grid>
              )}
              {chartData && chartData.channelData && (
                <Grid item xs={12} lg={6}>
                  <UserChart
                    userChartData={chartData.channelData}
                    title="channel"
                  />
                </Grid>
              )}

              {chartData && chartData.moviesData && (
                <Grid item xs={12} lg={6}>
                  <UserChart
                    userChartData={chartData.moviesData}
                    title="movie"
                  />
                </Grid>
              )}
              {chartData && chartData.seriesData && (
                <Grid item xs={12} lg={6}>
                  <UserChart
                    userChartData={chartData.seriesData}
                    title="series"
                  />
                </Grid>
              )}
            </Grid>
          </SoftBox>

          <SoftBox mb={3}>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={12} lg={6} justifyContent="center">
                <Typography>Content</Typography>
                <Table
                  columns={contentColumns}
                  rows={[]}
                  docCount={0}
                  // handleSort={handleSort}
                  message="No views exists for now.Coming soon."
                />
              </Grid>
              <Grid item xs={12} lg={6} justifyContent="center">
                <Typography>User</Typography>
                <Table
                  columns={usersColumns}
                  rows={[]}
                  docCount={0}
                  // handleSort={handleSort}
                  message="No views exists for now.Coming soon."
                />
              </Grid>
            </Grid>
          </SoftBox>

          {/*<SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <ReportsBarChart
                  title="active users"
                  description={
                    <>
                      (<strong>+23%</strong>) than last week
                    </>
                  }
                  chart={chart}
                  items={items}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <GradientLineChart
                  title="Sales Overview"
                  description={
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox
                        fontSize={size.lg}
                        color="success"
                        mb={0.3}
                        mr={0.5}
                        lineHeight={0}
                      >
                        <Icon className="font-bold">arrow_upward</Icon>
                      </SoftBox>
                      <SoftTypography
                        variant="button"
                        color="text"
                        fontWeight="medium"
                      >
                        4% more{" "}
                        <SoftTypography
                          variant="button"
                          color="text"
                          fontWeight="regular"
                        >
                          in 2021
                        </SoftTypography>
                      </SoftTypography>
                    </SoftBox>
                  }
                  height="20.25rem"
                  chart={gradientLineChartData}
                />
              </Grid>
            </Grid>
          </SoftBox> */}
          {/* <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrderOverview />
            </Grid>
          </Grid> */}
        </SoftBox>
      )}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
