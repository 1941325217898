import { Card, Grid, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ImagePreview from "components/ImagePreview";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import VideoPreview from "components/VideoPreview";
import dayjs from "dayjs";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import { DeleteReq } from "lib/requests";
import { PatchReq } from "lib/requests";
import { GetReq } from "lib/requests";
import { PostReq } from "lib/requests";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const EpisodeForm = () => {
  const { token } = useSelector((state) => state.user);

  const { seriesId } = useParams();

  const contentInputRef = useRef();

  const [initialState, setInitialState] = useState(
    {
      en: {
        title: "",
        plot: "",
      },
      sp: {
        title: "",
        plot: "",
      },
      poster: "",
      content: "",
      contentUrl: "",
      episodeNumber: 0,
      // value: 0,
      releaseDate: "",
      duration: "",
    },
  );

  const [seriesList, setSeriesList] = useState([])
  const [errorHandler, setErrorHandler] = useState(null);
  const [value, setValue] = useState(0);
  const [plotValue, setPlotValue] = useState(0);
  // const [currentEpisodeId, setCurrentEpisodeId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [currentEpisode, setCurrentEpisode] = useState({});

  useEffect(() => {
    getEpisode()
  }, [seriesId, token])

  const getEpisode = async () => {
    try {
      const { data } = await GetReq(`/content/series/${seriesId}`, true, token);
      if (data.status) {
        setSeriesList([...data.data])
      } else {
        toast.error(data.message)
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message)
    }
  }

  const posterInputRef = useRef();

  // const handleCard = () => {
  //   setInitialState((prevState) => [
  //     ...prevState,
  //     {
  //       en: {
  //         title: "",
  //         plot: "",
  //       },
  //       sp: {
  //         title: "",
  //         plot: "",
  //       },
  //       poster: "",
  //       content: "",
  //       contentUrl: "",
  //       episodeNumber: 0,
  //       // value: 0,
  //       releaseDate: "",
  //       duration: "",
  //     },
  //   ]);
  // };

  const handleChange = async (event, field, fieldType) => {
    const data = {
      ...initialState
    };
    if (field === "file") {
      if (fieldType === "poster") {
        const formData = new FormData();
        formData.append("poster", event.target.files[0]);
        const response = await PostReq(
          "poster-upload",
          formData,
          true,
          "multipart/form-data",
          token
        );
        if (response) {
          console.log(event.target.name, response.data.poster, "sdfasfasfd")
          console.log(data)

          data.poster = response?.data?.poster || "";
          console.log({ data })
          console.log(data.poster)

        }
      } else if (fieldType === "content") {
        const formData = new FormData();
        formData.append("poster", event.target.files[0]);
        const response = await PostReq(
          "poster-upload",
          formData,
          true,
          "multipart/form-data",
          token
        );
        if (response) {
          data[event.target.name] = response?.data?.poster;
        }
      }
    } else if (field === "date") {
      data["releaseDate"] = event;
    } else if (event.target.name === "en.title") {
      data["en"]["title"] = event.target.value;
    } else if (event.target.name === "sp.title") {
      data["sp"]["title"] = event.target.value;
    } else if (event.target.name === "en.plot") {
      data["en"]["plot"] = event.target.value;
    } else if (event.target.name === "sp.plot") {
      data["sp"]["plot"] = event.target.value;
    } else {
      data[event.target.name] = event.target.value;
    }

    // else {
    //   let exists = false;
    //   if (event.target.name === "episodeNumber") {
    //     for (const _field of initialState) {
    //       if (_field.episodeNumber === event.target.value) {
    //         exists = true;
    //       }
    //     }
    //     // if (exists) {
    //     //   setErrorHandler((prevState) => [
    //     //     ...prevState,
    //     //     { [index]: "This episode already exists" },
    //     //   ]);
    //     // } else {
    //     //   const _data = [];
    //     //   if (errorHandler && errorHandler.length > 0) {
    //     //     for (const obj of errorHandler) {
    //     //       if (obj[index]) {
    //     //         continue;
    //     //       } else {
    //     //         _data.push(obj);
    //     //       }
    //     //     }
    //     //   }

    //     //   setErrorHandler([..._data]);
    //     // }
    //   }


    setInitialState({ ...data });
    // setInitialState(
    //   (prevState) => (prevState[index][event.target.name] = event.target.value)
    // );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log({ initialState })
    initialState.duration = (initialState.duration).split(":").join(".")
    try {
      let response;
      if (editMode) {
        response = await PatchReq(
          `content/episode/${currentEpisode._id}`,
          { relatedTo: seriesId, data: initialState },
          true,
          "json",
          token
        );
        if (response.data.status) {
          toast.success(response.data.message)
          getEpisode()
          setInitialState({
            en: {
              title: "",
              plot: "",
            },
            sp: {
              title: "",
              plot: "",
            },
            poster: "",
            content: "",
            contentUrl: "",
            episodeNumber: 0,
            // value: 0,
            releaseDate: "",
            duration: "",
          })
        } else {
          toast.error(response.data.message)

        }
      } else {
        response = await PostReq(
          "upload-episode",
          { relatedTo: seriesId, data: initialState },
          true,
          "json",
          token
        );
        if (response.status) {
          toast.success(response.message)
          getEpisode();
          setInitialState({
            en: {
              title: "",
              plot: "",
            },
            sp: {
              title: "",
              plot: "",
            },
            poster: "",
            content: "",
            contentUrl: "",
            episodeNumber: 0,
            // value: 0,
            releaseDate: "",
            duration: "",
          })
        } else {
          toast.error(response.message)

        }
      }
      console.log(response);


    } catch (error) {
      console.log(error.response.data)
      toast.error(error?.response?.data?.message || error.message)
      // errorHandler(error);
    }
  };

  function Content({ title }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {title}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const rows = [];

  const columns = [
    { name: "content", align: "left" },
    { name: "episode number", align: "center" },
    { name: "content created", align: "center" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ];

  const editHandler = async (id) => {
    // setCurrentEpisodeId(id);
    try {
      const { data } = await GetReq(`/content/episode/${id}`, true, token);
      if (data.status) {
        setInitialState(
          {
            en: {
              title: data?.data?.translations?.en?.title || "",
              plot: data?.data?.translations?.en?.plot || "",
            },
            sp: {
              title: data?.data?.translations?.sp?.title || "",
              plot: data?.data?.translations?.sp?.plot || "",
            },
            poster: data?.data?.poster || "",
            content: data?.data?.content || "",
            contentUrl: data?.data?.contentUrl || "",
            episodeNumber: data?.data?.numberOfEpisodes || 0,
            // // value: 0,
            releaseDate: dayjs(data?.data?.releaseDate) || "",
            duration: data?.data?.duration?.toString().split(".").join(":") || "",
          }
        )
        setCurrentEpisode({ ...data.data })
        setEditMode(true)
      } else {
        toast.error(data.message)
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message)
    }
  }

  const deleteHandler = async (id) => {
    try {
      const { data } = await DeleteReq(`/content/episode/${id}`, true, token);

      if (data.status) {
        toast.success(data.message)
        getEpisode();
      } else {
        toast.error(data.message)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    }
  }


  for (const row of seriesList) {
    rows.push({
      content: <Content title={row.translations?.en?.title} />,
      "episode number": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.numberOfEpisodes}
        </SoftTypography>),
      "content created": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {new Date(row.createdAt).getDate()} /
          {new Date(row.createdAt).getMonth()} /
          {new Date(row.createdAt).getFullYear()}
        </SoftTypography>
      ),
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent={row.archived === false ? "active" : "Inactive"}
          color={row.archived === false ? "success" : "error"}
          size="xs"
          container
        />
      ),
      action: (
        <>

          <SoftButton
            color="info"
            variant="gradient"
            // onClick={handleWhiteSidenav}
            // disabled={disabled}
            // sx={sidenavTypeButtonsStyles}
            // onClick={() => deleteHandler(row._id)}
            onClick={() => editHandler(row._id)}
            sx={{ marginRight: "10px" }}
          >
            Edit
          </SoftButton>
          <SoftButton
            color="error"
            variant="gradient"
            sx={{ marginLeft: "10px" }}
            onClick={() => deleteHandler(row._id)}
          // disabled={disabled}
          // sx={sidenavTypeButtonsStyles}
          >
            Delete
          </SoftButton>
          {row.contentType === "series" && (
            <Link to={`/add-episode/${row._id}`}>
              <SoftButton
                color="info"
                variant="gradient"
                sx={{ marginLeft: "10px" }}
              // disabled={disabled}
              // sx={sidenavTypeButtonsStyles}
              >
                Episodes
              </SoftButton>
            </Link>
          )}
        </>
      ),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={8}>
          <Link to="/content">
            <Grid item xs={12}>
              <SoftButton fullWidth type="submit">
                {"<- Go back"}
              </SoftButton>
            </Grid>
          </Link>
          <Table
            columns={columns}
            rows={rows}
            // docCount={contentCount}
            // handleSort={handleSort}
            message="No content exists."
          />
          <form style={{ marginTop: "10px" }} onSubmit={handleSubmit}>
            {/* {initialState.map((_fields, index) => {
              return ( */}
            <Card sx={{ padding: "50px" }}>
              <Grid item xs={10}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <SoftBox mb={2}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Poster
                        </SoftTypography>
                      </SoftBox>
                      <input
                        name="poster"
                        id="poster"
                        hidden
                        ref={posterInputRef}
                        // value={initialState[index].poster}
                        // onBlur={handleBlur}
                        //   disabled={mode === "edit"}
                        onChange={(event) =>
                          handleChange(event, "file", "poster")
                        }
                        type="file"
                        placeholder="Content Poster"
                      />
                      <SoftButton
                        type="button"
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={() => posterInputRef.current.click()}
                      >
                        Add Poster
                      </SoftButton>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        color="error"
                      >
                        {/* {errors.email && touched.email && errors.email} */}
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  {initialState.poster && (
                    <ImagePreview image={initialState.poster} />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={6}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Title
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      sx={{ padding: "5px" }}
                      style={{ backgroundColor: "#f0f1f2" }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <SoftButton
                            type="button"
                            onClick={() => setValue(0)}
                            disabled={value === 0}
                            fullWidth
                            sx={{ padding: "1px !important" }}
                          >
                            English
                          </SoftButton>
                        </Grid>
                        <Grid item xs={6}>
                          <SoftButton
                            onClick={() => setValue(1)}
                            type="button"
                            disabled={value === 1}
                            fullWidth
                          >
                            Spainesh
                          </SoftButton>
                        </Grid>
                      </Stack>
                    </Card>
                  </Grid>
                </Stack>
              </Grid>
              {value === 0 && (
                <SoftBox mb={2}>
                  <SoftInput
                    name="en.title"
                    id="en.title"
                    value={initialState.en["title"]}
                    // onBlur={handleBlur}
                    onChange={(event) => handleChange(event)}
                    // onChange={(event) => {
                    //   setFieldValue("en.title", event.target.value);
                    // }}
                    type="text"
                    placeholder="Title"
                  />
                </SoftBox>
              )}
              {value === 1 && (
                <SoftBox mb={2}>
                  <SoftInput
                    name="sp.title"
                    id="sp.title"
                    value={initialState.sp["title"]}
                    // onBlur={handleBlur}
                    onChange={(event) => handleChange(event)}
                    // onChange={(event) => {
                    //   setFieldValue("en.title", event.target.value);
                    // }}
                    type="text"
                    placeholder="Title"
                  />
                </SoftBox>
              )}

              <Grid item xs={12} mb={2}>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={6}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Plot
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      sx={{ padding: "5px" }}
                      style={{ backgroundColor: "#f0f1f2" }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <SoftButton
                            type="button"
                            onClick={() => setPlotValue(0)}
                            disabled={plotValue === 0}
                            fullWidth
                            sx={{ padding: "1px !important" }}
                          >
                            English
                          </SoftButton>
                        </Grid>
                        <Grid item xs={6}>
                          <SoftButton
                            onClick={() => setPlotValue(1)}
                            type="button"
                            disabled={plotValue === 1}
                            fullWidth
                          >
                            Spanish
                          </SoftButton>
                        </Grid>
                      </Stack>
                    </Card>
                  </Grid>
                </Stack>
              </Grid>
              {plotValue === 0 && (
                <SoftBox mb={2}>
                  <SoftInput
                    name="en.plot"
                    id="en.plot"
                    value={initialState.en["plot"]}
                    // onBlur={handleBlur}
                    onChange={(event) => handleChange(event)}
                    // onChange={(event) => {
                    //   setFieldValue("en.plot", event.target.value);
                    // }}
                    type="text"
                    placeholder="Plot"
                  />
                </SoftBox>
              )}
              {plotValue === 1 && (
                <SoftBox mb={2}>
                  <SoftInput
                    name="sp.plot"
                    id="sp.plot"
                    value={initialState.sp["plot"]}
                    // onBlur={handleBlur}
                    onChange={(event) => handleChange(event)}
                    // onChange={(event) => {
                    //   setFieldValue("en.plot", event.target.value);
                    // }}
                    type="text"
                    placeholder="Plot"
                  />
                </SoftBox>
              )}
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Content Url
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="contentUrl"
                  id="contentUrl"
                  value={initialState.contentUrl}
                  // onBlur={handleBlur}
                  onChange={(event) => handleChange(event)}
                  type="text"
                  placeholder="contentUrl"
                />
              </SoftBox>
              <Grid item xs={10}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <SoftBox mb={2}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Content
                        </SoftTypography>
                      </SoftBox>
                      <input
                        name="content"
                        id="content"
                        hidden
                        ref={contentInputRef}
                        //   value={values.type}
                        //   disabled={mode === "edit"}
                        onChange={(event) => handleChange(event, "file", "content")}
                        type="file"
                        placeholder="Content"
                      />
                      <SoftButton
                        type="button"
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={() => contentInputRef.current.click()}
                      >
                        Add Content
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                  {initialState.content && <VideoPreview video={initialState.content} />}
                </Stack>
              </Grid>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Episode Number
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="episodeNumber"
                  id="episodeNumber"
                  value={initialState.episodeNumber}
                  // onBlur={handleBlur}
                  //   disabled={mode === "edit"}
                  onChange={(event) => handleChange(event)}
                  type="number"
                  placeholder="Content Episode Number in minutes"
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {/* {errors.email && touched.email && errors.email} */}
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Duration
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="duration"
                  id="duration"
                  value={initialState.duration}
                  onChange={(event) => {
                    if (
                      event.target.value[event.target.value.length - 1] == 1 ||
                      event.target.value[event.target.value.length - 1] == 2 ||
                      event.target.value[event.target.value.length - 1] == 3 ||
                      event.target.value[event.target.value.length - 1] == 4 ||
                      event.target.value[event.target.value.length - 1] == 5 ||
                      event.target.value[event.target.value.length - 1] == 6 ||
                      event.target.value[event.target.value.length - 1] == 7 ||
                      event.target.value[event.target.value.length - 1] == 8 ||
                      event.target.value[event.target.value.length - 1] == 9 ||
                      event.target.value[event.target.value.length - 1] == 0 ||
                      event.target.value[event.target.value.length - 1] == ":" ||
                      event.target.value == ""
                    ) {
                      handleChange(event)
                    }
                    // setFieldValue("duration", event.target.value);
                  }}
                  // onKeyDown
                  type="text"
                  placeholder="Content duration in minutes : second"
                />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Release Date
                  </SoftTypography>
                </SoftBox>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      id="releaseDate"
                      name="releaseDate"
                      value={initialState.releaseDate}
                      onChange={(date) => handleChange(date, "date")}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {/* {errors.email && touched.email && errors.email} */}
                </SoftTypography>
              </SoftBox>
              {/* {errorHandler &&
                errorHandler.length > 0 &&
                errorHandler.map((error, _index) => {
                  if (error[index]) {
                    return (
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        color="error"
                      >
                        {error[index]}
                      </SoftTypography>
                    );
                  } else {
                    return <></>;
                  }
                })} */}

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* <Grid item xs={5}>
                        {index === initialState.length - 1 && (
                          <SoftButton
                            fullWidth
                            type="button"
                            onClick={handleCard}
                          >
                            Add
                          </SoftButton>
                        )}
                      </Grid> */}
                  <Grid item xs={12}>
                    <SoftButton color="info"
                      variant="gradient" fullWidth type="submit">
                      {editMode ? "Edit Episode" : "Add Episode"}
                    </SoftButton>
                  </Grid>
                </Stack>
              </Grid>
            </Card>
            {/* );
            })} */}
          </form>
        </Grid>
      </Grid>
    </DashboardLayout>
  );

}
export default EpisodeForm;
