import React, { useState } from "react";
import { CardMedia } from "@mui/material";
import { IMAGE_BASE_URL } from "constants";

const VideoPreview = ({ file, video }) => {
  const [preview, setPreview] = useState(null);

  if (file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }

  return (
    <CardMedia
      src={preview || (video && `${IMAGE_BASE_URL}${video}`)}
      alt="preview"
      component="video"
      autoPlay
      controls
      variant="square"
      sx={{ width: "200px", height: "100px" }}
    />
  );
};

export default VideoPreview;
