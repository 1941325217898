// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
import PeopleIcon from "@mui/icons-material/People";
import HubIcon from "@mui/icons-material/Hub";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import TopicIcon from "@mui/icons-material/Topic";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: ["", "dashboard"],
    route: "/",
    icon: <Shop size="12px" />,
    noCollapse: false,
  },
  {
    type: "collapse",
    name: "User Management",
    key: ["users", "add-user", "edit-user"],
    route: "/users",
    icon: <PeopleIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Talent Management",
    key: ["talent", "add-talent", "edit-talent"],
    route: "/talent",
    icon: <HowToRegIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Channel Management",
    key: ["channel", "add-channel", "edit-channel"],
    route: "/channel",
    icon: <HubIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Genre Management",
    key: ["genre", "add-genre", "edit-genre"],
    route: "/genre",
    icon: <LocalOfferIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Content Management",
    key: ["content", "add-content"],
    route: "/content",
    icon: <VideoLibraryIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Subscription Management",
    key: ["subscription", "add-subscription"],
    route: "/subscription",
    icon: <TopicIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "CMS Management",
    key: ["cms", "edit-cms"],
    route: "/cms",
    icon: <Office size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Email Template Management",
    key: ["email-template", "add-email-template", "edit-email-template"],
    route: "/email-template",
    icon: <Office size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Template Management",
    key: ["template", "add-template"],
    route: "/template",
    icon: <Office size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "ADS Management",
    key: ["ADS", "add-ADS"],
    route: "/ads",
    icon: <Office size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Interest Management",
    key: ["interest", "add-interest"],
    route: "/interest",
    icon: <Office size="12px" />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   route: "/billing",
  //   icon: <CreditCard size="12px" />,
  //   component: <Billing />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Virtual Reality",
  //   key: "virtual-reality",
  //   route: "/virtual-reality",
  //   icon: <Cube size="12px" />,
  //   component: <VirtualReality />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   route: "/rtl",
  //   icon: <Settings size="12px" />,
  //   component: <RTL />,
  //   noCollapse: true,
  // },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   icon: <CustomerSupport size="12px" />,
  //   component: <Profile />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   route: "/authentication/sign-in",
  //   icon: <Document size="12px" />,
  //   component: <SignIn />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <SpaceShip size="12px" />,
  //   component: <SignUp />,
  //   noCollapse: true,
  // },
];

export default routes;
