import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ImagePreview from "components/ImagePreview";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import VideoPreview from "components/VideoPreview";
import Multiselect from "multiselect-react-dropdown";
import React from "react";

const DetailForm = ({
  posterInputRef,
  handleBlur,
  setFieldValue,
  values,
  handleChange,
  multiSelectStyle,
  languages,
  handleSelect,
  handleRemove,
  trailerInputRef,
  contentInputRef,
  genres,
  formCount,
  setFormCount,
  errorMessage,
  series,
  mode
}) => {
  return (
    <>
      <Grid item xs={10}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Poster
                </SoftTypography>
              </SoftBox>
              <input
                name="poster"
                id="poster"
                hidden
                ref={posterInputRef}
                //   value={values.type}
                onBlur={handleBlur}
                //   disabled={mode === "edit"}
                onChange={(event) => {
                  setFieldValue("poster", event.target.files[0]);
                }}
                type="file"
                placeholder="Content Poster"
              />
              <SoftButton
                type="button"
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => posterInputRef.current.click()}
              >
                Add Poster
              </SoftButton>
              {errorMessage && errorMessage.poster && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errorMessage.poster}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          {(values.poster || values.imageValue) && (
            <ImagePreview file={values.poster} image={values.imageValue} />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={3}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Show Home
                </SoftTypography>
              </SoftBox>
              <Switch
                id="homePage"
                name="homePage"
                checked={values.homePage}
                onChange={handleChange}
                value={values.homePage}
              />
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="error"
              ></SoftTypography>
            </SoftBox>
          </Grid>

          <Grid item xs={3}>
            {values.homePage && (
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Slider
                  </SoftTypography>
                </SoftBox>
                <Switch
                  id="slider"
                  name="slider"
                  checked={values.slider}
                  onChange={handleChange}
                  value={values.slider}
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                ></SoftTypography>
              </SoftBox>
            )}
          </Grid>
          <Grid item xs={6}>
            {values.homePage && (
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Priority
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="priority"
                  id="priority"
                  value={values.priority}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue("priority", event.target.value);
                  }}
                  type="number"
                  placeholder="Priority"
                />
              </SoftBox>
            )}
          </Grid>
        </Stack>
      </Grid>
      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Duration
          </SoftTypography>
        </SoftBox>
        <SoftInput
          name="duration"
          id="duration"
          value={values.duration}
          onBlur={handleBlur}
          onChange={(event) => {
            if (
              event.target.value[event.target.value.length - 1] == 1 ||
              event.target.value[event.target.value.length - 1] == 2 ||
              event.target.value[event.target.value.length - 1] == 3 ||
              event.target.value[event.target.value.length - 1] == 4 ||
              event.target.value[event.target.value.length - 1] == 5 ||
              event.target.value[event.target.value.length - 1] == 6 ||
              event.target.value[event.target.value.length - 1] == 7 ||
              event.target.value[event.target.value.length - 1] == 8 ||
              event.target.value[event.target.value.length - 1] == 9 ||
              event.target.value[event.target.value.length - 1] == 0 ||
              event.target.value[event.target.value.length - 1] == ":" ||
              event.target.value == ""
            ) {
              setFieldValue("duration", event.target.value);
            }
            // setFieldValue("duration", event.target.value);
          }}
          // onKeyDown
          type="text"
          placeholder="Content duration in minutes : second"
        />
        {errorMessage && errorMessage.duration && (
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="error"
          >
            {errorMessage.duration}
          </SoftTypography>
        )}
      </SoftBox>
      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Languages
          </SoftTypography>
        </SoftBox>
        <Multiselect
          hidePlaceholder
          style={multiSelectStyle}
          className="form-control"
          options={languages} // Options to display in the dropdown
          displayValue="name"
          onSelect={(event) => handleSelect(event, values, "languages")}
          onRemove={(event) => handleRemove(event, values, "languages")}
          // selectionLimit={1}
          // style={{}}
          closeOnSelect
          selectedValues={values.languages}
        // singleSelect={true}
        />

        {errorMessage && errorMessage.languages && (
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="error"
          >
            {errorMessage.languages}
          </SoftTypography>
        )}
      </SoftBox>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={5}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Release Date
                </SoftTypography>
              </SoftBox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    id="releaseDate"
                    name="releaseDate"
                    value={values.releaseDate}
                    onChange={(date) => {
                      setFieldValue("releaseDate", date);
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>

              {errorMessage && errorMessage.releaseDate && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errorMessage.releaseDate}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          {/* <Grid item xs={5}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Plan Type
                </SoftTypography>
              </SoftBox>
              <FormControl sx={{ width: "100% !important" }}>
                <Select
                  value={values.planType}
                  name="planType"
                  id="planType"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    paddingLeft: "0px !important",
                  }}
                >
                  <MenuItem id="planType" value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem id="planType" value="premium">
                    Premium
                  </MenuItem>
                  <MenuItem id="planType" value="free">
                    Free
                  </MenuItem>
                </Select>
              </FormControl>
              {errorMessage && errorMessage.planType && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errorMessage.planType}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid> */}
        </Stack>
      </Grid>


      {values.contentType === "series" && (
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Total episodes
            </SoftTypography>
          </SoftBox>
          <SoftInput
            name="totalEpisodes"
            id="totalEpisodes"
            value={values.totalEpisodes}
            onBlur={handleBlur}
            //   disabled={mode === "edit"}
            onChange={handleChange}
            type="number"
            placeholder="Content total episodes."
          />
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="error"
          >
            {/* {errors.email && touched.email && errors.email} */}
          </SoftTypography>
        </SoftBox>
      )}

      {values.contentType === "series" && (
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Season Number
            </SoftTypography>
          </SoftBox>
          <SoftInput
            name="seasonNumber"
            id="seasonNumber"
            value={values.seasonNumber}
            onBlur={handleBlur}
            //   disabled={mode === "edit"}
            onChange={handleChange}
            type="number"
            placeholder="Content season number."
          />
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="error"
          >
            {/* {errors.email && touched.email && errors.email} */}
          </SoftTypography>
        </SoftBox>
      )}

      {/* {values.contentType === "series" && (
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Total Season
            </SoftTypography>
          </SoftBox>
          <SoftInput
            name="totalSeason"
            id="totalSeason"
            value={values.totalSeason}
            onBlur={handleBlur}
            //   disabled={mode === "edit"}
            onChange={handleChange}
            type="number"
            placeholder="Number of season  ."
          />
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="error"
          >

          </SoftTypography>
        </SoftBox>
      )} */}

      {values.contentType === "series" && (
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Series
            </SoftTypography>
          </SoftBox>
          <FormControl sx={{ width: "100% !important" }}>
            <Select
              value={values.relatedTo}
              name="relatedTo"
              id="relatedTo"
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              sx={{
                paddingLeft: "0px !important",
              }}
            >
              <MenuItem id="relatedTo" value="">
                <em>None</em>
              </MenuItem>
              {series.length > 0 &&
                series.map((_series) => (
                  <MenuItem id="relatedTo" value={_series._id} key={_series._id}>
                    {_series?.translations?.en?.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="error"
          >
            {/* {errors.email && touched.email && errors.email} */}
          </SoftTypography>
        </SoftBox>
      )}



      {
        !values.slider && (
          <Grid SoftBox mb={2} >
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Trailer URL
              </SoftTypography>
            </SoftBox>
            <SoftInput
              name="trailerUrl"
              id="trailerUrl"
              value={values.trailerUrl}
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue("trailerUrl", event.target.value);
              }}
              type="text"
              placeholder="trailerUrl"
            />
            {/* {
              errorMessage && errorMessage.trailerUrl && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errorMessage.trailerUrl}
                </SoftTypography>
              )
            } */}
          </Grid>
        )
      }



      <Grid item xs={10}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Trailer
                </SoftTypography>
              </SoftBox>
              <input
                name="trailer"
                id="trailer"
                hidden
                ref={trailerInputRef}
                //   value={values.type}
                onBlur={handleBlur}
                //   disabled={mode === "edit"}
                onChange={(event) => {
                  setFieldValue("trailer", event.target.files[0]);
                }}
                type="file"
                placeholder="Content trailer"
              />
              <SoftButton
                type="button"
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => trailerInputRef.current.click()}
              >
                Add Trailer
              </SoftButton>
              {errorMessage && errorMessage.trailer && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errorMessage.trailer}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          {
            mode === 'add' && (
              <>
                {values.trailer && <VideoPreview file={values.trailer} />}
              </>
            )
          }
          {
            mode === 'edit' && (
              <>
                {values.trailer && <VideoPreview video={values.trailer} />}
              </>
            )
          }

        </Stack>
      </Grid>

      {/* {
        values.contentType === "movie" && <Grid item xs={5}>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Content Format
              </SoftTypography>
            </SoftBox>
            <FormControl sx={{ width: "100% !important" }}>
              <Select
                value={values.contentFormat}
                name="contentFormat"
                id="contentFormat"
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  paddingLeft: "0px !important",
                }}
              >
                <MenuItem id="contentFormat" value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem id="contentFormat" value="url">
                  Url
                </MenuItem>
                <MenuItem id="contentFormat" value="content">
                  Content
                </MenuItem>
              </Select>
            </FormControl>
            {errorMessage && errorMessage.contentFormat && (
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="error"
              >
                {errorMessage.contentFormat}
              </SoftTypography>
            )}
          </SoftBox>
        </Grid>
      } */}

      {
        values.contentType === "movie" && !values.slider && (
          <Grid SoftBox mb={2} >
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Content URL
              </SoftTypography>
            </SoftBox>
            <SoftInput
              name="contentUrl"
              id="contentUrl"
              value={values.contentUrl}
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue("contentUrl", event.target.value);
              }}
              type="text"
              placeholder="contentUrl"
            />
            {/* {
              errorMessage && errorMessage.contentUrl && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errorMessage.contentUrl}
                </SoftTypography>
              )
            } */}
          </Grid>
        )
      }
      {
        values.contentType === "movie" && (
          <Grid item xs={10}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Content
                    </SoftTypography>
                  </SoftBox>
                  <input
                    name="content"
                    id="content"
                    hidden
                    ref={contentInputRef}
                    //   value={values.type}
                    onBlur={handleBlur}
                    //   disabled={mode === "edit"}
                    onChange={(event) => {
                      setFieldValue("content", event.target.files[0]);
                    }}
                    type="file"
                    placeholder="Content"
                  />
                  <SoftButton
                    type="button"
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={() => contentInputRef.current.click()}
                  >
                    Add Content
                  </SoftButton>
                  {errorMessage && errorMessage.content && (
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="error"
                    >
                      {errorMessage.content}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              {
                mode === 'add' && (
                  <>
                    {values.content && <VideoPreview file={values.content} />}
                  </>
                )
              }
              {
                mode === 'edit' && (
                  <>
                    {values.content && <VideoPreview video={values.content} />}
                  </>
                )
              }
            </Stack>
          </Grid>
        )}





      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Status
          </SoftTypography>
        </SoftBox>
        <Switch
          id="status"
          name="status"
          checked={values.status}
          onChange={handleChange}
          value={values.status}
        />
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          color="error"
        >
          {/* {errors.email && touched.email && errors.email} */}
        </SoftTypography>
      </SoftBox>
    </>
  );
};

export default DetailForm;
